import React from "react";

import image from "../images/c.jpg";

const imageAltText = "Background image";

const description =
  "I'm a Computer Engineering student studying at Technion – Israel Institute of Technology.";
const skillsList = [
  "Programming: C/C++, C#, Python, Assembly, Java",
  "Tools: Linux, VS Code, Github, GitHub Copilot, Jupyter Notebooks",
];

const About = () => {
  return (
    <section className="padding" id="about">
      <img className="background" src={image} alt={imageAltText} />

      <div
        style={{
          backgroundColor: "white",
          width: "50%",
          padding: "4rem",
          margin: "3rem auto",
          textAlign: "center",
        }}
      >
        <h2>About Myself</h2>
        <p className="large">{description}</p>
        <hr />
        <ul
          style={{
            textAlign: "left",
            columns: 2,
            fontSize: "1.25rem",
            margin: "2rem 3rem",
            gap: "3rem",
          }}
        >
          {" "}
          {skillsList.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
        <hr />
      </div>
    </section>
  );
};

export default About;
